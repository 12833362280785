body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.pagination {
  margin: 1rem auto;
  list-style: none;
  cursor: pointer;
  display: flex;
  justify-content: space-evenly;
  width: 75%;
}

.activePaginate {
  background-color: #009374;
  display: inline-block;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  padding: 0 3px;
  outline: none;
  color: #fff;
}
